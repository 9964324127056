<template>
  <div>
    <div v-if="loading" class="text-center">
      <loading size="60"></loading>
      <br /><br />
      <h3 class="text-center">Der Unternehmenswert wird berechnet ...</h3>
    </div>

    <div v-else>
      <div v-if="!upgrade.requested">
        <p class="text-center mb-4">
          <b-icon icon="check2-circle" style="height: 60px; width: 60px; color: var(--primary);"/>
        </p>
        <h3 id="unternehmenswert" class="text-center">
          Der Unternehmenswert beträgt<br /><em>{{ $n(valuation.value, "currency", "de-DE") }}</em>
          &nbsp;<b-icon id="label_unternehmenswert" icon="info-circle" style="width: 1.35rem; color: var(--primary);"></b-icon>
          <b-tooltip target="label_unternehmenswert" triggers="hover">
            Der ausgewiesene Unternehmenswert enthält auch das „nicht betriebsnotwendige Vermögen" (NBV). Das NBV wird nachrichtlich in Kapitel 6 des Bewertungsreports PRO gesondert ausgewiesen.
          </b-tooltip>
        </h3>
      </div>

      <!-- BASIC Flow -->
      <div v-if="isQuestionaireTypeBasic" class="upgrade-container">

        <div v-if="!upgrade.requested">

          <div class="mb-4 text-center">
            <h5>Sie wünschen Ihren Unternehmenswert als individuellen Bericht in Ihrem Emailpostfach?<br/>Hier sind Ihre Optionen:</h5>
          </div>

          <div class="my-3 justify-content-center">
            <b-card-group deck>

              <!-- PRO Upgrade -->
              <b-card no-body class="mb-1" bg-variant="light">
                <b-card-header header-tag="header" class="p-3 text-center">
                  <b-icon icon="award"></b-icon>&nbsp;
                  <strong>PRO – <a href="https://www.kmu-value.de/files/theme/user/downloads/KMUVALUE_Beispielbericht_PRO-Report.pdf" target="_blank">Ihr individueller Detailbericht</a></strong>
                </b-card-header>
                <b-card-body>
                  <b-card-text>
                    <p>
                      Durch ein <strong>kostenpflichtiges</strong> Upgrade können wir Ihnen die PRO Version von <strong>KMUVALUE für 249€ zzgl. MwSt.</strong> zur Verfügung stellen. Sie erhalten einen professionellen Bewertungsbericht zu Ihrem individuellen Unternehmenswert.
                    </p>
                    <p>Das Upgrade beinhaltet folgende Zusatzleistungen:</p>
                    <ul>
                      <li class="included"><span>Kompaktwissen Unternehmensbewertung</span></li>
                      <li class="included"><span>Analyse der Ist-Situation</span></li>
                      <li class="included"><span>Darstellung der Unternehmensplanung</span></li>
                      <li class="included"><span>Detaillierte Herleitung des Unternehmenswerts nach dem Modifizierten Ertragswertverfahren</span></li>
                      <li class="included"><span>Erläuterung und Herleitung des Unternehmenswerts nach dem Multiplikatorverfahren</span></li>
                      <li class="included"><span>Optional: Individueller Experten-Support</span></li>
                    </ul>
                    <p>
                      Im Rahmen der PRO Version wird Ihre Unternehmensbewertung individualisiert und per elektronischem Bewertungsreport (professioneller Bewertungsbericht als PDF-Datei) direkt an Sie übermittelt.
                    </p>

                    <!-- Upgrade durchdführen -->
                    <p>
                      Hierzu benötigen wir zunächst Ihre E-Mail Adresse:
                    </p>

                    <b-form>
                      <b-row>
                        <b-col>
                          <b-form-group :state="$v.upgrade.email.$error ? false : null">
                            <b-form-input
                              v-model="$v.upgrade.email.$model"
                              type="email"
                              placeholder="name@unternehmen.de"
                              :state="$v.upgrade.email.$error ? false : null"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>

                    <b-link class="btn btn-lg btn-primary mt-3" style="width: 100%" :href="pspUrl" :disabled="$v.upgrade.email.$invalid">Upgrade zu PRO »</b-link>
                  </b-card-text>
                </b-card-body>
              </b-card>

              <!-- BASIC Upgrade -->
              <b-card no-body class="mb-1" bg-variant="light">
                <b-card-header header-tag="header" class="p-3 text-center">
                  <b-icon icon="file-earmark-richtext"></b-icon>&nbsp;
                  <strong>BASIC – <a href="https://www.kmu-value.de/files/theme/user/downloads/KMUVALUE_Beispielbericht_BASIC-Report.pdf" target="_blank">Ihre Kurzübersicht</a></strong>
                </b-card-header>
                <b-card-body>
                  <b-card-text>
                    <p>
                      Durch ein <strong>kostenloses</strong> Upgrade können wir Ihnen die BASIC Version von <strong>KMUVALUE</strong> zur Verfügung stellen. Sie erhalten einen Kurzbericht zu Ihrem individuellen Unternehmenswert.<br/><br/>
                    </p>
                    <p>Das Upgrade beinhaltet folgende Zusatzleistungen:</p>
                    <ul>
                      <li class="included">Zusammenfassung Ihrer Bewertung</li>
                      <li class="not-included"><span class="text-muted not-included">Kompaktwissen Unternehmensbewertung</span></li>
                      <li class="not-included"><span class="text-muted not-included">Analyse der Ist-Situation</span></li>
                      <li class="not-included"><span class="text-muted not-included">Darstellung der Unternehmensplanung</span></li>
                      <li class="not-included"><span class="text-muted not-included">Detaillierte Herleitung des Unternehmenswerts nach dem Modifizierten Ertragswertverfahren</span></li>
                      <li class="not-included"><span class="text-muted not-included">Erläuterung und Herleitung des Unternehmenswerts nach dem Multiplikatorverfahren</span></li>
                      <li class="not-included"><span class="text-muted not-included">Optional: Individueller Experten-Support</span></li>

                    </ul>
                    <p>
                      Im Rahmen der BASIC Version wird Ihre Unternehmensbewertung individualisiert und per elektronischem Bewertungsreport (Kurzübersicht) an Sie übermittelt.
                    </p>
                    <p>
                      Hierzu benötigen wir Ihre E-Mail Adresse:
                    </p>
                  </b-card-text>

                  <!-- Basic Report bestellen -->
                  <div v-if="!upgrade.requested" class="mt-3">
                    <b-row>
                      <b-col>
                        <b-form-group :state="$v.upgrade.email.$error ? false : null">
                            <b-form-input
                              v-model="$v.upgrade.email.$model"
                              type="email"
                              placeholder="name@unternehmen.de"
                              :state="$v.upgrade.email.$error ? false : null"
                            ></b-form-input>
                          </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-link class="btn btn-lg btn-primary mt-3" style="width: 100%" @click.prevent="upgradeReport('basic')" href="#" :disabled="$v.upgrade.email.$invalid">Upgrade zu BASIC »</b-link>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-card-group>
          </div>
        </div>

        <div v-else class="upgrade-container">

          <!-- Auf Bericht warten -->
          <div class="kmu-financialdata--workflow-container justify-content-center">
            Ihre Unternehmensbewertung wird nun individualisiert und Ihnen in Kürze automatisiert per E-Mail zugesendet.
          </div>

        </div>
      </div>

      <!-- PRO Flow -->
      <div v-else class="upgrade-container">

        <!-- Bericht erstellen -->
        <div v-if="!upgrade.requested" class="mt-3">
          <p class="text-center mb-5">Bitte nennen Sie uns die Emailadresse, an die der PRO Bericht geschickt werden soll.</p>
          <b-row>
            <b-col>
              <b-form-group :state="$v.upgrade.email.$error ? false : null">
                <b-input-group prepend="Email" size="lg">
                  <b-form-input
                    v-model="$v.upgrade.email.$model"
                    type="email"
                    placeholder="name@unternehmen.de"
                    :state="$v.upgrade.email.$error ? false : null"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-link class="btn btn-primary"
                      :disabled="$v.upgrade.email.$invalid"
                      @click.prevent="upgradeReport('pro')"
                      href="#"
                    >
                    PRO Bericht zusenden »
                    </b-link>
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else class="upgrade-container">
          <!-- Auf Bericht warten -->
          <div class="kmu-financialdata--workflow-container justify-content-center">
            Ihre Unternehmensbewertung wird nun individualisiert und Ihnen in Kürze automatisiert per E-Mail zugesendet.
          </div>
        </div>
      </div>

    </div>

    <!-- Schedule an appointment modal -->
    <b-modal id="schedule-consulting" centered button-size="lg" title="Persönliche Beratung zählt!">
      <p class="my-4">
        <strong>Vielen Dank</strong>, dass Sie <strong>KMUVALUE</strong> zur Bewertung Ihres Projektes verwendet haben.
      </p>
      <p>
        Sollten Sie weitergehende Fragen zur Bewertung haben, oder wollen Sie Ihren <em>individuellen Fall</em> eingehender besprechen, können Sie unter <strong>"Termin buchen"</strong> Ihren persönlichen Termin direkt <strong>online auswählen</strong>.
      </p>
      <template #modal-footer>
        <b-button size="lg" variant="primary" @click="onSchedule()">
          »&nbsp;Termin&nbsp;buchen&nbsp;«
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";
import Loading from "@/components/Loading.vue";

import { mapState, mapMutations } from "vuex";

import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    Loading
  },

  data() {
    return {
      upgrade: {
        email: null,
        requested: false
      },
      loading: false,
      pending: false,
      errors: null,
      pollInterval: null
    };
  },

  mounted() {
    this.upgrade = Object.assign({}, this.upgrade, this.upgradeState)

    if (!this.isValuated) {
      this.sendData()
    }
  },

  methods: {
    receiveMessage (event) {
      if (typeof event.data === 'string') {
        try {
          var data = JSON.parse(event.data);
        } catch (event) {
          // do nothing
        }

        if (data && data.payrexx) {
          Array.forEach(data.payrexx, function(name, value) {
            switch (name) {
              case 'transaction':
                if (typeof value === 'object') {
                  //transaction = value;
                  console.log('transaction: ' + value)
                }
                break;
              case 'closeModal':
                window.location.href = value;
                break;
            }
          });
        }
      }
    },

    sendData() {
      this.loading = true;
      axios
        .post(
          "https://api.kmu-value.de/flows/trigger/8533a342-5490-4d60-bf4a-c11add1f1a88/",
          {
            case: this.questionaireCase,
            name: this.answers.projektname,
            answers: this.answers,
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.setValuation({
              case: response.data.case,
              report: response.data.report
            })

            // call and see if data is calculated already ...
            this.fetchData();

            // open the "schedule a meeting" modal
            // after 6 seconds.
            // setTimeout(() => {
            //   this.schedule();
            // }, 6000);

            //save reference to the interval
            this.pollInterval = setInterval(this.fetchData, 1000)
            //stop polling after a minute
            setTimeout(() => {
               clearInterval(this.pollInterval)
            }, 60000)
          }
        })
        .catch((error) => {
          this.errors = error;
        });
    },

    fetchData() {
      axios.get(
        'https://api.kmu-value.de/items/report/' + this.valuation.report
        )
        .then((response) => {
          const data = response.data.data;
          //check if status is completed, if it is stop polling
          if (!data.pending) {

            //won't be polled anymore
            clearInterval(this.pollInterval)

            this.setValuation({
              report: data.id,
              flow : data.result?.flow,
              case: data.result?.case,
              value: data.result?.valuation
            })

            this.loading = false;
          }

          this.pending = data.pending;
        })
    },

    upgradeReport(reportType = 'basic') {
      axios
        .post(
          "https://api.kmu-value.de/flows/trigger/c812d37f-cf6d-4883-9ecf-f4a46592ba47/",
          {
            product: reportType,
            case: this.valuation.case,
            email: this.upgrade.email,
            answers: this.answers
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.upgrade.requested = true;
            this.resetStorage()
            this.finish()
          }
        })
        .catch((error) => {
          this.errors = error;
        });
    },

    finish() {
      // redirect to the start after all
      setTimeout(() => {
        this.$router.push({ name: "intro" });
      }, 10000);
    },

    schedule() {
      this.$bvModal.show('schedule-consulting')
    },

    onSchedule() {
      this.$bvModal.hide('schedule-consulting')
      window.open('https://outlook.office365.com/owa/calendar/KMUVALUE1@butz-consult.de/bookings/', '_blank').focus()
    },

    ...mapMutations('questions', {
      resetModule: 'resetModule'
    }),

    ...mapMutations({
      setValuation: "setValuation",
      resetValuation: "resetValuation",
      setUpgrade: "setUpgrade",
      resetUpgrade: "resetUpgrade",
      resetStorage: "resetStorage",
    })
  },

  computed: {
    pspUrl() {
      let url = 'https://kmu-value.payrexx.com/pay?tid=7c1fa1e5'

      url += '&contact_email=' + this.upgrade?.license?.email
      url += '&custom_Flow=' + this.valuation?.flow
      url += '&custom_Case=' + this.valuation?.case

      return url
    },

    isValuated() {
      return this.valuation && this.valuation.flow
    },

    isQuestionaireTypeBasic() {
      return this.questionaireType === 'BASIC'
    },

    ...mapState("questions", {
      answers: (state) => state.answers,
    }),

    ...mapState({
      questionaireType: (state) => state.questionaireType,
      questionaireCase: (state) => state.questionaireCase,
      upgradeState: (state) => state.upgrade,
      valuation: (state) => state.valuation
    }),
  },
  // watch: {
  //   "$route.query.payment": {
  //     handler: function(payment) {
  //       let result = false

  //       if (payment) {
  //         result = payment === 'success'

  //         // if (result) {
  //         //   this.upgrade.license.isOrdered = true
  //         //   this.upgrade.license.transaction = this.$route.query.transaction
  //         // }
  //       }

  //       this.upgrade.license.ordering = false

  //       // this.setUpgradeLicenseResult({
  //       //   ordering: this.upgrade.license.ordering,
  //       //   isOrdered: this.upgrade.license.isOrdered,
  //       //   transaction: this.upgrade.license.transaction
  //       // })

  //       return result
  //     },

  //     immediate: true,
  //   }
  // },
  validations() {
    return {
      upgrade: {
        email: { required, email }
      }
    }
  }
};
</script>

<style lang="scss">
.modal {
  padding-top: 100px;
}

.upgrade-container {
  margin: auto;
  margin-top: 5rem;
}

.kmu-summary--license-container {
  box-shadow: 0 4px 8px 0 rgba(17, 22, 26, 0.16),
    0 4px 8px 0 rgba(17, 22, 26, 0.08), 0 8px 16px 0 rgba(17, 22, 26, 0.04);
}

.kmu-summary--license-container-header {
  align-items: center;
  justify-content: center;
  background-color: var(--kmu-color2-200);
  display: flex;
  padding: 1rem;
  font-size: 1.6rem;
  font-style: italic;
  font-stretch: expanded;
}

.kmu-summary--license-container {
  .valid-feedback {
    padding-left: 1.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
  }
}

.kmu-summary--license-container {
  .invalid-feedback {
    padding-left: 1.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
  }
}

.btn-payrexx {
	display: inline-block;
	width: auto;
	padding: 6px 20px 6px 20px;
	font-size: 1.375rem;
	white-space: inherit;
	background-color: var(--primary);
	border: 0;
	outline: 0;
	text-decoration: none;
	color: #212121;
	border-radius: 3px;
	-webkit-transition: background-color 300ms ease-out;
	-moz-transition: background-color 300ms ease-out;
	-o-transition: background-color 300ms ease-out;
	transition: background-color 300ms ease-out;
}

.btn-payrexx:focus, .btn-payrexx:hover {
	background-color: var(--primary);
	color: #ffffff;
}

li::marker {
  color: var(--primary);
}

.not-included {
  text-decoration: line-through;
  list-style-type: circle;
}
</style>
